export const ROUTES = {
  AUTH: {
    LOGIN: '/api-v1/auth/login',
    BECAME_USER: (userId: string) => `/api-v1/admins/users/${userId}/login`,
    SIGN_UP: '/api-v1/auth/signup',
    REFRESH_TOKEN: '/api-v1/auth/refresh',
    FORGOT_PASSWORD: '/api-v1/auth/forgot-password',
    RESET_PASSWORD: '/api-v1/auth/reset-password',
    RESET_PASSWORD_CHECK: '/api-v1/auth/reset-password-check',
    LOGOUT: '/api-v1/auth/logout',
    DELETE: '/api-v1/auth/delete-account',
    GOOGLE_AUTH: '/api-v1/auth/google-login',
    FACEBOOK_AUTH: '/api-v1/auth/facebook-login',
    GOOGLE_SIGNUP: '/api-v1/auth/google-signup',
    FACEBOOK_SIGNUP: '/api-v1/auth/facebook-signup',
    APPLE_SIGNUP: '/api-v1/auth/apple-signup',
    APPLE_SIGNUP_FINALIZE: '/api-v1/auth/apple-signup-finalize',
    APPLE_LOGIN: '/api-v1/auth/apple-login',
    ENABLE_2FA: '/api-v1/clients/user-settings/2fa/enable',
    DISABLE_2FA: '/api-v1/clients/user-settings/2fa/disable',
    ACTIVATE_2FA: '/api-v1/auth/2fa-qr-code',
    VERIFY_2FA: '/api-v1/auth/verify2fa',
  },
  QR_BUILDER: {
    TEMPLATE_OPTIONS: '/api-v1/common/qr-categories',
  },
  QR_CODES_GUEST_API: {
    BASE: '/api-v1/qr-codes/guest',
  },
  QR_CODES_API: {
    BASE: '/api-v1/qr-codes',
    RESET_SCANS: '/api-v1/qr-codes/reset-scans',
    ACTIVATE: '/api-v1/qr-codes/activate',
    DEACTIVATE: '/api-v1/qr-codes/deactivate',
    URI: '/api-v1/common/qr-codes/uri',
    COMMON: {
      VCARD: '/api-v1/common/qr-codes/vcard',
    },
  },
  MEDIA: {
    CLOUDINARY: {
      UPLOAD_CONFIG: '/api-v1/common/media/signature',
      DELETE: '/api-v1/cloudinary/media/',
      REPORT_UPLOAD: '/api-v1/common/media/front-upload-complete',
    },
  },
  SUBSCRIPTION: {
    PRODUCTS: '/api-v1/common/products',
    CURRENCIES: '/api-v1/common/products/currencies',
    USER_PRODUCTS: '/api-v1/clients/products',
    CURRENT: '/api-v1/clients/subscriptions',
    CREATE_STRIPE: '/api-v1/clients/subscriptions/stripe',
    CREATE_PAYPAL: '/api-v1/clients/subscriptions/paypal',
    CREATE_PAYPAL_INITIAL: '/api-v1/clients/subscriptions/paypal/initial',
    CANCEL: (subscriptionId: string) => `/api-v1/clients/subscriptions/cancel/${subscriptionId}`,
    REACTIVATE: (subscriptionId: string) => `/api-v1/clients/subscriptions/reactivate/${subscriptionId}`,
    COMMON: {
      CANCEL_REQUEST: '/api-v1/common/subscriptions/cancel-request',
      CANCEL: (subscriptionId: string) => `/api-v1/common/subscriptions/cancel/${subscriptionId}`,
    },
  },
  BILLING: {
    PAYMENT_DETAILS: '/api-v1/clients/billing/payment-details',
    TRANSACTIONS: '/api-v1/clients/billing/transactions',
    BILLING_PAY: '/api-v1/clients/billing/pay',
    CHANGE_CARD: '/api-v1/clients/billing/change-card',
    INVOICE: (transactionId: string) => `/api-v1/clients/billing/transactions/${transactionId}/invoice`,
  },
  USER_SETTINGS: {
    ABANDONED_CART: '/api-v1/clients/user-settings/abandoned-cart',
    FIRST_LOGIN_MARKETING: '/api-v1/clients/user-settings/first-login-marketing',
    EVENTS_STREAM: '/api-v1/clients/user-settings/events-stream',
    GET_SETTINGS_INFO: '/api-v1/clients/user-settings',
    GET_USER: '/api-v1/clients/user-settings/get-user',
    GENERAL_INFO: '/api-v1/clients/user-settings/general-info',
    COMPANY_INFO: '/api-v1/clients/user-settings/company-info',
    CHANGE_PASSWORD: '/api-v1/clients/user-settings/change-password',
    CHANGE_EMAIL: '/api-v1/clients/user-settings/change-email',
    GET_TIMEZONES: '/api-v1/clients/user-settings/timezones',
    CHANGE_TIMEZONE: '/api-v1/clients/user-settings/change-timezone',
    GET_LANGUAGES: '/api-v1/clients/user-settings/languages',
    GET_NATIVE_LANGUAGES: '/api-v1/clients/user-settings/native-languages',
    GET_COUNTRIES: '/api-v1/clients/user-settings/countries',
    CHANGE_LANGUAGE: '/api-v1/clients/user-settings/change-language',
    BANNER_CLICKED: '/api-v1/clients/user-settings/banner',
  },
  STATISTICS: {
    BATCH: '/api-v1/clients/analytics/batch',

    FILTERS_QR_CODE: '/api-v1/clients/analytics/filters/qr-code',
    FILTERS_OS: '/api-v1/clients/analytics/filters/os',
    FILTERS_COUNTRIES: '/api-v1/clients/analytics/filters/countries',
    FILTERS_CITIES: '/api-v1/clients/analytics/filters/cities',

    EXPORT: '/api-v1/clients/analytics/export',
  },
  TRANSLATIONS: {
    UPDATE: '/api-v1/admins/translations',
  },
} as const;
